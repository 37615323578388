<template>

  <nav aria-label="Page navigation">
    <ul class="pagination minimalist">
      <li class="page-item" v-for="(page, p) in pages" :key="`pagination-item-${p}`">
        <a :class="['page-link', { 'active': page.active, 'disabled': !page.url }]" href="javascript:void(0)"
          @click="handleClick(page)">{{ page.label }}</a>
      </li>
    </ul>
  </nav>


</template>

<script>
export default {
  name: "Pagination",
  props: {
    prev_page: {
      type: String,
      default: null
    },
    next_page: {
      type: String,
      default: null
    },
    pages: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleClick(page) {
      if (page.url && !page.active) {
        this.$emit('handle-link-click', page);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination.minimalist {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  display: inline-block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background-color 0.2s, color 0.2s;
}

.page-link:hover {
  background-color: #004a7c !important;
  color: white;
  border: 1px solid #004a7c !important;
}

.page-link.active {
  background-color: #004a7c;
  color: white;
  border: 1px solid #004a7c;
  pointer-events: none;
}

.page-link[aria-label="Previous"],
.page-link[aria-label="Next"] {
  font-weight: 600;
  color: #000;
}
</style>