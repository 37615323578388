<template>
  <div class="common_sub_container">
    <Loader v-if="loader.is_loading" :text="loader.text" />
    <h2>INVOICE RECONCILIATION</h2>
    <section>
      <form class="custom-form" @submit.prevent="uploadInvoice">
        <!-- Name Input -->
        <div class="form-group">
          <label for="name">Select Carrier</label>
          <div class="custom-select-wrapper">
            <select v-model="selected_carrier" class="form-control custom-select" @change="changeCarrier()">
              <option v-for="(carrier, c) in carriers" :key="'carriers-' + c" :value="carrier.value"
                :disabled="carrier.disabled">{{ carrier.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group" v-for="(file, type) in files" :key="`file-import-${type}`"
          v-if="files[type].enable_for.includes(selected_carrier)">

          <label :for="`${type}-file-field`">{{ files[type].labels[selected_carrier] }}</label>
          <div class="custom-file">
            <input type="file" :ref="`${type}File`" @change="handleFileUpload(type, $event)"
              class="form-control custom-file-input" :id="`${type}File`" accept=".csv, .xls, .xlsx">
            <label class="custom-file-label" :for="`${type}File`">{{ files[type].file_name }}</label>
          </div>

          <!-- Custom Alert HTML -->
          <div class="custom-alert" v-if="files[type].alert">
            <span class="alert-message">
              {{ files[type].alert }}
            </span>
          </div>
        </div>


        <div class="custom-btn-wrapper">
          <button type="submit" :class="['btn', 'btn-primary', { 'disabled': button.disabled }]"
            :disabled="button.disabled">{{ button.text }}</button>
        </div>

      </form>
    </section>
    <section>
      <div class="table-responsive">
        <div class="table-header">
          <h3 class="title">Reconciliation Tasks</h3>
          <!-- <ActionButton type="warning" @handle-click="goToReconciliationHistory()">
            Reconciliation History
          </ActionButton> -->
        </div>
        <div class="search-form">
          <label for="search-text" class="search-label">Search:</label>
          <input type="text" id="search-text" placeholder="Filter File Name" name="text" v-model="search.text"
            class="search-input" @input="filterTask()" />

          <label for="carrier-select" class="select-label">Carrier:</label>
          <select id="carrier-select" name="carrier-select" class="search-select" v-model="search.carrier"
            @change="filterTask()">
            <option value="all">All</option>
            <option v-for="(carrier, c) in carriers" :key="'carriers-search-' + c" :value="carrier.value"
              :disabled="carrier.disabled">{{ carrier.name }}
            </option>
          </select>
        </div>
        <table class="custom-table">
          <thead>
            <tr>
              <th rowspan="2">Carrier</th>
              <th rowspan="2">Files</th>
              <th rowspan="2">Status</th>
              <th colspan="2">Uploaded</th>
              <th colspan="7">Invoice Break Down</th>
              <th colspan="2">Reconciliation</th>
              <th rowspan="2">Action</th>
            </tr>
            <tr>
              <th>User</th>
              <th>Date Time</th>
              <th>Uploaded</th>
              <th>Matched</th>
              <th>Unmatched</th>
              <th>Multiple</th>
              <th>Duplicated</th>
              <th>Included</th>
              <th>Dropped</th>
              <th>User</th>
              <th>Date Time</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="reconciliation_tasks && reconciliation_tasks.data.length > 0">
              <tr v-for="(task, i) in reconciliation_tasks.data">
                <td>{{ task.carrier }}</td>
                <td style="text-align: start;">
                  <p v-for="(file, f) in task.invoice_files">
                    {{ file.file_name }}
                  </p>
                </td>
                <td>
                  <Badge :type="task.status == 'reconciled' ? 'success' : 'info'">{{ capitalizeFirstLetter(task.status)
                    }}</Badge>
                </td>
                <td>{{ task.uploaded_by.name }}</td>
                <td>{{ task.created_at | formatDateTime }}</td>
                <td>{{ task.uploaded_invoice_count }}</td>
                <td>{{ task.matched_invoice_data.length }}</td>
                <td>{{ task.unmatched_invoice_data.length }}</td>
                <td>{{ task.same_invoice_duplicate_data.length }}</td>
                <td>{{ task.duplicated_invoice_data.length }}</td>
                <td>{{ task.included_count }}</td>
                <td>{{ task.dropped_count }}</td>
                <template v-if="task.status == 'reconciled'">
                  <td>{{ task.processed_by.name }}</td>
                  <td>{{ task.processed_at | formatDateTime }}</td>
                </template>
                <template v-else>
                  <td colspan="2"> - </td>
                </template>
                <td>
                  <div  class="action-btns">
                    <ActionButton type="primary" :title="`View reconciliation task`" @handle-click="gotoProccess(task.encrypted_id)" :className="['action-btn-padding']">
                      <i class="material-icons p-0 m-0">visibility</i>
                    </ActionButton>
                    <ActionButton v-if="task.status == 'uploaded'" type="danger"
                      @handle-click="deleteTask(task.encrypted_id)" :title="`Delete reconciliation task`" :className="['action-btn-padding']">
                      <i class="material-icons p-0 m-0">delete</i>
                    </ActionButton>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="15">No Reconciliation Task Found</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <Pagination v-if="reconciliation_tasks && reconciliation_tasks.data.length > 0"
        :prev_page="reconciliation_tasks.prev_page_url" :next_page="reconciliation_tasks.next_page_url"
        :pages="reconciliation_tasks.links" @handle-link-click="handlePaginationClick" />
    </section>
  </div>
</template>
<script>
import Badge from "../components/Badge.vue";
import ActionButton from "../components/ActionButton.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";

import moment from "moment";

export default {
  name: "InvoiceReconciliation",
  components: {
    Badge,
    ActionButton,
    Loader,
    Pagination
  },
  filters: {
    formatDate(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYY/MM/DD');
    },
    formatTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('HH:mm');
    },
    formatDateTime(value) {
      if (!value) {
        return;
      }

      return moment(String(value)).format('YYYY/MM/DD HH:mm');
    }
  },
  async created() {
    this.$emit("breadCrumbData", ["Invoice Check", "Invoice Reconciliation"]);

    await this.getInvoiceReconciliationTasks();
  },
  data() {
    return {
      files: {
        main: {
          file: null,
          file_name: 'Choose file',
          alert: null,
          enable_for: ['DHL', 'STC', 'TGE'],
          labels: {
            DHL: 'DHL Invoice File',
            STC: 'Startrack Invoice File',
            TGE: 'TollPriority Invoice File',
          }
        },
        secondary: {
          file: null,
          file_name: 'Choose file',
          alert: 'Please make sure last invoice period invoices uploaded and reconciled !',
          enable_for: ['STC'],
          labels: {
            STC: 'Startrack Courier Invoice File',
          }
        },
      },
      loading: false,
      selected_carrier: 'DHL',
      selected_carrier_name: 'DHL',
      search: {
        text: '',
        carrier: 'all'
      },
      reconciliation_tasks: null,
      button: {
        text: 'Upload',
        disabled: false
      },
      loader: {
        text: "",
        is_loading: false
      },
      carriers: [
        {
          name: 'DHL',
          value: 'DHL',
          disabled: false,
        },
        {
          name: 'Startrack',
          value: 'STC',
          disabled: false,
        },
        {
          name: 'TollPriority',
          value: 'TGE',
          disabled: false,
        }
      ],
    };
  },
  watch: {
    selected_carrier: function (newValue, oldValue) {
      const selectedCarrier = this.carriers.find((el) => el.value === newValue);

      this.selected_carrier_name = selectedCarrier ? selectedCarrier.name : '';
    }
  },
  methods: {
    handleFileUpload(type, event) {

      const file = event.target.files[0];
      if (file) {
        this.files[type].file = file;
        this.files[type].file_name = file.name;
      } else {
        this.files[type].file_name = 'Choose file';
      }

    },
    resetFile(key) {
      this.files[key].file = null;
      this.files[key].file_name = 'Choose file';

      if (this.$refs[`${key}File`] != undefined) {
        this.$refs[`${key}File`][0].value = '';
      }
    },
    resetForm() {
      this.selected_carrier = 'DHL';
      this.resetFile('main');
      this.resetFile('secondary');
    },
    changeCarrier() {
      this.resetFile('main');
      this.resetFile('secondary');
    },
    async handlePaginationClick(page) {
      let url = new URL(page.url).pathname + new URL(page.url).search;
      await this.getInvoiceReconciliationTasks(true, url);
    },
    async filterTask() {
      await this.getInvoiceReconciliationTasks(false);
    },

    async getInvoiceReconciliationTasks(loader = true, url = `/api/invoice-reconciliation/tasks`) {
      if (loader) {
        this.loader.is_loading = true;
        this.loader.text = "Please Wait...";
      }

      try {
        const response = await this.axios.get(url, {
          params: {
            text: this.search.text,
            carrier: this.search.carrier,
          }
        });

        this.reconciliation_tasks = response.data.reconciliation_tasks;

      } catch (error) {
        console.error("Error fetching invoice reconciliation tasks:", error);
      } finally {

        if (loader) {
          this.loader.is_loading = false;
          this.loader.text = "";
        }
      }
    },
    async uploadInvoice() {

      if (this.selected_carrier == null || this.selected_carrier == '') {
        this.toast.error(`Please select Carrier`);
        return;
      }

      const filesArray = Object.entries(this.files);

      let formData = new FormData();
      formData.append("selected_carrier", this.selected_carrier);

      var file_error = false;

      for (const [type, file] of filesArray) {
        if (file.enable_for.includes(this.selected_carrier) && (file.file == null || file.file === '')) {
          this.toast.error(`Please Select ${file.labels[this.selected_carrier]}`);
          file_error = true;
          break;
        }

        if ((file.file !== null && file.file !== '')) {
          formData.append(`files[${type}]`, file.file);
        }

      }

      if (file_error) {
        return;
      }

      // this.button.text = 'Uploading...';
      this.button.disabled = true;

      this.loader.text = "Invoice file uploading...";
      this.loader.is_loading = true;

      await this.axios
        .post("/api/invoice-reconciliation/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          const res = response.data;

          this.resetForm();

          this.toast.success(res.msg);

          const task_id = res.reconciliation_task_id;

          if (task_id) {
            this.$router.push({ name: 'InvoiceReconciliationProcess', params: { id: task_id } });
          }

          this.getInvoiceReconciliationTasks();

        })
        .catch(error => {
          console.log(error);
        });

      // this.button.text = 'Upload';
      this.button.disabled = false;

      this.loader.text = "";
      this.loader.is_loading = false;
    },
    gotoProccess(id) {
      this.$router.push({ name: 'InvoiceReconciliationProcess', params: { id: id } });
    },
    // goToReconciliationHistory() {
    //   this.$router.push({ name: 'ReconciliationHistory' });
    // },
    capitalizeFirstLetter(string) {
      if (!string) return string;

      return string
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    },
    async deleteTask(id) {
      if (confirm('Are you certain you want to remove this reconciliation task?')) {

        await this.axios
          .delete(`/api/invoice-reconciliation/tasks/${id}`)
          .then(async response => {
            const res = response.data;

            this.toast.success(res.msg);

            await this.getInvoiceReconciliationTasks(false);

          })
          .catch(error => {
            console.log(error);
          });

      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-btn-padding {
  padding: 7px 10px 10px 10px !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.select_dropdown {
  width: 120% !important;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-header .title {
  font-weight: bold;
  // margin-bottom: 5px;
}

.search-form {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 15px;
  width: auto;
  margin-block: 10px;
}

/* Label style */
.search-label,
.select-label {
  margin-right: 10px;
  font-size: 16px;
  color: #000;
}

/* Input field style */
.search-input {
  padding: 10px;
  width: auto;
  min-width: 150px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 16px;
  margin-right: 15px;
  width: 300px;
}


/* Select dropdown style */
.search-select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  outline: none;
  background-color: white;
  cursor: pointer;
  width: 200px;
}

// table

.table-responsive {
  overflow-x: auto;
  margin: 20px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding-inline: 5px;
  padding-block: 12px;
  border: 1px solid #dee2e6;
  text-align: center;
  /* Center text horizontally */
}

.custom-table th {
  background-color: #f8f9fa;
  color: #495057;
}

.custom-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.custom-table tr:hover {
  background-color: #e9ecef;
}

.row-checkbox {
  cursor: pointer;
}

.custom-table .action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-table .action-btns>.custom-action-button {
  margin-right: 5px;
}

.custom-table .action-btns>.custom-action-button:last-child {
  margin-right: 0 !important;
}

/* custom form */
.custom-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 1.5rem;
  background-color: #f8f9fa;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #495057;
}

.form-control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

/* custom file upload */

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  // max-width: 400px;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 40px;
  margin: 0;
  opacity: 0;
}

.custom-file-label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
  background-color: #fff;
  border: 1px solid #ced4da;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-file-input:disabled+.custom-file-label {
  background-color: #e9ecef;
  color: #495057;
  cursor: not-allowed;
}

.custom-file-label::after {
  content: "Browse";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  padding: 0.375rem 0.75rem;
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Custom Select Dropdown */
.custom-select-wrapper {
  width: 100%;
  // max-width: 400px;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: auto;
}

.custom-select-wrapper::after {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #495057;
}

.custom-select-wrapper select:disabled {
  background-color: #e9ecef;
  color: #6c757d;
}

// custom btn
.btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  font-size: 1rem;
  line-height: 50px;
  color: #fff;
  background-color: #004a7c;
  width: 200px;
  height: 50px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
}

.custom-btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-alert .alert-message {
  margin-right: 10px;
}

.custom-alert {
  width: 460px;
  text-align: justify;
  padding: 15px 20px;
  background-color: #f8d7da;
  color: #721c24; 
  border: 1px solid #f5c6cb; 
  border-radius: 4px; 
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transition: opacity 0.5s ease, transform 0.5s ease;
  margin-top: 20px;
}
</style>